<template>
    <v-container>
        <v-dialog v-model="dialog" persistent fullscreen>
            <v-toolbar dark>
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('package_service.' + action) }}</v-toolbar-title>
                <v-spacer />
                <v-btn color="blue darken-1" @click="save(action)">{{ $t('common.save') }}</v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form" v-model="formValid" lazy-validation>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="packageServiceDetailObj.name"
                                        :rules="[v => !!v || $t('common.required')]" :label="$t('package_service.name')" />
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field v-model="packageServiceDetailObj.price" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                    ]" :label="$t('common.price')" />
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field v-model="packageServiceDetailObj.discountPrice" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number'),
                                        v => parseInt(v) <= parseInt(packageServiceDetailObj.price) || $t('common.valid_price')
                                    ]" :label="$t('common.discount_price')" />
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="packageServiceDetailObj.quantity" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                    ]" :label="$t('common.quantity')" />
                                </v-col>
                                <v-col cols="4">
                                    <v-textarea v-model="packageServiceDetailObj.descriptionShort"
                                        :label="$t('package_service.descriptionShort')" rows="2" />
                                </v-col>
                                <v-col cols="4">
                                    <v-textarea v-model="packageServiceDetailObj.descriptionLong"
                                        :label="$t('package_service.descriptionLong')" rows="2" />
                                </v-col>
                                <v-col cols="4">
                                    <v-textarea v-model="packageServiceDetailObj.precautions"
                                        :label="$t('package_service.precautions')" rows="2" />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-if="hasDate" v-model="startAtEndAt" :label="$t('service.time')" readonly
                                        @click="hasDate = false" />
                                    <date-picker v-else v-model="timeArr" type="datetime" clearable value-type="format"
                                        format="YYYY/MM/DD HH:mm" range :placeholder="$t('service.time')" />
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="packageServiceDetailObj.category" :items="categoryList"
                                        item-value="_id" :item-text="(lang == 'tc') ? 'nameZh' : 'nameEng'"
                                        :label="$t('package_service.category')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>
                                <v-col cols="12" v-if="packageServiceDetailObj.category == '61c2beee91779a388cef5e1b'">
                                    <v-select multiple v-model="packageServiceDetailObj.aspects" :items="aspectList"
                                        item-value="id" :item-text="(lang == 'tc') ? 'textZh' : 'textEng'"
                                        :label="$t('setting.aspects')" />
                                </v-col>
                                <v-col cols="12" v-if="action == 'edit'">
                                    <v-switch v-model="packageServiceDetailObj.published"
                                        :label="showStatus(packageServiceDetailObj.published)" />
                                </v-col>
                                <v-col cols="12" v-if="permissions == 'admin'">
                                    <v-combobox v-model="packageServiceDetailObj.merchantId" :items="merchantsList"
                                        item-value="_id" item-text="name" :label="$t('merchant.name')"
                                        :rules="[v => !!v || $t('common.required')]" outlined />
                                </v-col>
                            </v-row>
                            <v-row v-if="packageServiceDetailObj.merchantId">
                                <v-col cols="12">
                                    <v-card class="px-3 mb-7" v-for="(sessions, key) in packageServiceDetailObj.sessions"
                                        :key="key">
                                        <v-card-title>
                                            {{ $t('common.sessions') + (key + 1) }}
                                            <v-spacer />
                                            <v-btn dark @click="removeMultiRow(key)">
                                                <v-icon>mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row v-for="(item, sessionIndex) in sessions.availableServices"
                                                :key="sessionIndex">
                                                <v-col>
                                                    <p>{{ showServiceName(item.service) }}</p>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col class="justify-center d-flex flex-row">
                                    <v-btn dark @click="createSession">
                                        {{ $t('package_session.create') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-card>
                                        <v-card-title>{{ $t('common.image') }}</v-card-title>
                                        <v-card-text>
                                            <draggable v-if="packageServiceDetailObj.image"
                                                v-model="packageServiceDetailObj.image" @start="drag = true"
                                                @end="drag = false" tag="div" class="row" draggable=".draggableImage">
                                                <v-col v-for="(image, index) in packageServiceDetailObj.image"
                                                    :key="image._id" class="d-flex child-flex draggableImage" cols="2"
                                                    style="height:235px">
                                                    <v-hover>
                                                        <template v-slot:default="{ hover }">
                                                            <v-card>
                                                                <v-img max-height="150" v-if="image.large"
                                                                    :src="image.large.path" aspect-ratio="1"
                                                                    class="grey lighten-2">
                                                                    <template v-slot:placeholder>
                                                                        <v-row class="fill-height ma-0" align="center"
                                                                            justify="center">
                                                                            <v-progress-circular indeterminate
                                                                                color="grey lighten-5" />
                                                                        </v-row>
                                                                    </template>
                                                                </v-img>
                                                                <v-fade-transition>
                                                                    <v-overlay v-if="hover" absolute color="#036358">
                                                                        drag to re-odering
                                                                    </v-overlay>
                                                                </v-fade-transition>
                                                                <v-card-actions>
                                                                    <v-btn v-if="permissions == 'admin'" style="z-index:999"
                                                                        icon @click="copyImage(index)">
                                                                        <v-icon>mdi-content-copy</v-icon>
                                                                    </v-btn>
                                                                    <v-spacer />
                                                                    <v-btn style="z-index:999" icon
                                                                        @click="removeImage(index)">
                                                                        <v-icon>mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </template>
                                                    </v-hover>
                                                </v-col>
                                            </draggable>
                                            <v-col cols="12" key="newImage">
                                                <v-card style="height:100%;">
                                                    <v-container>
                                                        <file-pond name="upload_image" ref="pond"
                                                            :label-idle="$t('img_label')"
                                                            accepted-file-types="image/jpeg, image/png"
                                                            :files="uploadImageFile" allow-multiple="false"
                                                            max-file-size="5MB" @processfile="removeFile" />
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-if="action == 'edit'">
                                <v-col cols="6">
                                    <v-text-field
                                        :value="$moment(packageServiceDetailObj.updatedAt).format('YYYY/MM/DD HH:mm')"
                                        disabled :label="$t('common.updated_at')" />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        :value="$moment(packageServiceDetailObj.createdAt).format('YYYY/MM/DD HH:mm')"
                                        disabled :label="$t('common.created_at')" />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <SessionDetailDialog ref="SessionDetailDialog" @refresh="returnCreatedSession" />
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import api from '@/api'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import draggable from 'vuedraggable';
import SessionDetailDialog from "@/views/PackageSession/SessionDialog"

// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

export default {
    components: {
        FilePond,
        DatePicker,
        draggable,
        SessionDetailDialog
    },
    mounted() {
        let self = this
        this.setOptions({
            server: {
                process: async function (fieldName, files, metadata, load, error, progress, abort) {
                    try {
                        let formData = new FormData()
                        formData.append('file', files)

                        let result = await api.post('/image', formData, {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        })

                        if (result) {
                            self.packageServiceDetailObj.image.push(result.data);
                        }
                        load(true)
                    } catch (err) {
                        console.log(err.message);
                        abort(true)
                    }
                }
            },
            imageResizeMode: 'contain',
        })
    },
    computed: {
        ...mapState(['lang', 'stateMerchantId', 'stateMerchantName', 'permissions']),
        i18nSessionHeaders() {
            let result = this.sessionHeaders

            result.map(item => {
                item.text = this.$t(item.i18n)
            })

            return result
        },
        startAtEndAt() {
            let result = ''

            if (this.packageServiceDetailObj.startAt && this.packageServiceDetailObj.endAt && this.action == 'edit') {
                result = this.$moment(this.packageServiceDetailObj.startAt).format('YYYY/MM/DD HH:mm') + ' ~ ' + this.$moment(this.packageServiceDetailObj.endAt).format('YYYY/MM/DD HH:mm')
            }

            return result
        }
    },
    props: ['merchantsList', 'teacherList', 'areasList', 'serviceList', 'categoryList'],
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async open(action, item) {
            if (action == 'edit') {
                let result = false

                try {
                    result = await api.get('/servicePackages/' + item._id)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                if (result) {
                    this.packageServiceDetailObj = result.data
                    console.log(result.data)
                    //mapping time
                    this.timeArr[0] = this.$moment(this.packageServiceDetailObj.startAt).format('YYYY/MM/DD HH:mm')
                    this.timeArr[1] = this.$moment(this.packageServiceDetailObj.endAt).format('YYYY/MM/DD HH:mm')
                    this.hasDate = true
                    this.packageServiceDetailObj.category = result.data["category"] == null ? "" : result.data.category["_id"];
                }
            }

            if (this.permissions != 'admin') this.packageServiceDetailObj.merchantId = this.stateMerchantId

            await this.getSettingByAlias();

            this.action = action
            this.dialog = true
        },
        async getSettingByAlias() {
            this.settingObj = {}
            try {

                let result2 = await api.get('/setting/aspects')

                if (result2.data) {
                    this.aspectList = JSON.parse(result2.data['contentZh'])
                    //console.log(this.aspectList);
                }

            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
                return false
            }
        },
        async save() {
            let validated = this.$refs.form.validate()

            if (validated) {
                let result = false
                this.loading = true

                if (this.packageServiceDetailObj.image == '') {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_upload_img'),
                        icon: 'mdi-close',
                    })

                    return false
                }

                if (this.packageServiceDetailObj.sessions == '' || this.packageServiceDetailObj.sessions.length == 0) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_select_session'),
                        icon: 'mdi-close',
                    })

                    return false
                }

                if (!this.timeArr.length) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_time'),
                        icon: 'mdi-close',
                    })

                    return false
                }

                if (this.packageServiceDetailObj.category == '61c2beee91779a388cef5e1b' && this.packageServiceDetailObj.aspects.length > 3 && this.permissions != 'admin') {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_aspect_length'),
                        icon: 'mdi-close',
                    })

                    return false
                }

                this.packageServiceDetailObj.startAt = this.$moment(this.timeArr[0]).toISOString()
                this.packageServiceDetailObj.endAt = this.$moment(this.timeArr[1]).toISOString()
                if (this.permissions == 'admin') this.packageServiceDetailObj.merchantId = this.packageServiceDetailObj.merchantId._id

                try {
                    if (this.action == 'create') {
                        result = await api.post('/servicePackages', this.packageServiceDetailObj)
                    } else {
                        result = await api.patch('/servicePackages/' + this.packageServiceDetailObj._id, this.packageServiceDetailObj)
                    }

                    if (result) {
                        let targetI18n = (this.action === 'edit') ? 'global_alert.success_edit' : 'global_alert.success_create'
                        this.resetForm()
                        this.dialog = false
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t(targetI18n),
                            icon: 'mdi-check',
                        })
                    }
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                this.loading = false
            }
        },

        showServiceName(service) {
            let result = ''

            if (service && service.name) {
                result = service.name
            } else {
                if (this.serviceList && this.serviceList != '') {
                    this.serviceList.map(item => {
                        if (item._id == service) result = item.name
                    })
                }
            }

            return result
        },
        createSession() {
            this.$refs.SessionDetailDialog.open(this.packageServiceDetailObj.merchantId)
        },
        returnCreatedSession(item) {
            this.packageServiceDetailObj.sessions.push(item)
        },
        showStatus(status) {
            return status ? this.$t('common.enabled') : this.$t('common.disabled')
        },
        close() {
            this.resetForm()
            this.dialog = false
        },
        removeImage(index) {
            this.packageServiceDetailObj.image.splice(index, 1)
        },
        copyImage(index) {
            var toCopy = this.packageServiceDetailObj.image[index]["small"]["path"];
            navigator.clipboard.writeText(toCopy);
        },
        addNewMultiRow() {
            this.packageServiceDetailObj.sessions.push({ _id: '' })
        },
        removeMultiRow(index) {
            this.packageServiceDetailObj.sessions.splice(index, 1)
        },
        resetForm() {
            this.$emit('refresh')
            this.uploadImageFile = []
            this.timeArr = [null, null]
            this.packageServiceDetailObj = {
                name: '',
                merchantId: '',
                price: '',
                area: '',
                discountPrice: '',
                descriptionShort: '',
                descriptionLong: '',
                precautions: '',
                image: [],
                published: true,
                startAt: null,
                endAt: null,
                sessions: [],
                category: '',
                aspects: [],
            }
            this.hasDate = false
        },
        removeFile(error, file) {
            this.$refs.pond.removeFile(file)
        },
    },
    data: () => ({
        setOptions,
        dialog: false,
        uploadImageFile: [],
        timeArr: [null, null],
        sessionHeaders: [
            { i18n: 'service.name', value: 'name' }
        ],
        aspectList: [],
        packageServiceDetailObj: {
            name: '',
            merchantId: '',
            price: '',
            area: '',
            discountPrice: '',
            descriptionShort: '',
            descriptionLong: '',
            precautions: '',
            image: [],
            published: true,
            startAt: null,
            endAt: null,
            sessions: [],
            category: '',
            aspects: [],
        },
        action: 'edit',
        formValid: true,
        hasDate: false,
        openMenu: false,
        closeMenu: false,
    })
}
</script>