<template>
    <v-container fluid class="mt-8">
        <v-row :class="(permissions != 'admin') ? 'mb-5' : 'mb-0'">
            <v-col cols="6" v-if="permissions == 'admin'">
                <v-autocomplete v-model="selectedMerchant" :items="merchantsList" item-value="_id" item-text="name"
                    :label="$t('merchant.name')" solo dense clearable />
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn dark @click="create">
                    {{ $t('package_service.create') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table mobile-breakpoint=0 class="elevation-4" :headers="i18nHeader" :loading="loading" :items="packageList"
            :options.sync="options" sort-by="createdAt" :sort-desc="true" :server-items-length="totalService"
            :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }">
            <template v-slot:item="{ item, index }">
                <tr>
                    <td>{{ item.name }} </td>
                    <td>{{ item.merchantName }} </td>
                    <td>{{ item.price }} </td>
                    <td>{{ $moment(item.createdAt).format('YYYY/MM/DD HH:mm') }}</td>
                    <td>
                        <v-btn small :color="(item.published) ? 'success' : 'error'" @click="changeStatus(item, index)">
                            <v-icon left>mdi-{{ (item.published) ? 'check' : 'close' }}</v-icon>
                            {{ showStatus(item.published) }}</v-btn>
                    </td>
                    <td>
                        <v-icon @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                    </td>
                    <td>
                        <v-icon @click="copy(item, index)">
                            mdi-content-copy
                        </v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <FormDialog ref="dialog" @refresh="getAllPackage" :merchantsList="merchantsList" :sessionsList="sessionsList"
            :teacherList="teacherList" :areasList="handledAreaList" :serviceList="serviceList"
            :categoryList="categoryList" />
        <confirmDialog ref="confirmDialog" />
    </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import FormDialog from "@/views/PackageService/ServiceDialog"
import confirmDialog from "@/components/ConfirmDialog"

import api from '@/api'

export default {
    components: {
        FormDialog,
        confirmDialog
    },
    mounted() {
        // this.getAllPackage()
        this.getAllMerchant()
        this.getAllTeacher()
        this.getAllSessions()
        this.getAllAreas()
        this.getAllService()
        this.getAllCategories()
    },
    computed: {
        ...mapState(['lang', 'permissions']),
        i18nHeader() {
            let result = this.headers

            result.map(item => {
                item.text = this.$t(item.i18n)
            })

            return result
        },
        handledAreaList() {
            let result = []
            if (this.areasList && this.areasList != '') {
                let areaArr = this.areasList['area']
                let location = this.areasList['subArea']

                areaArr.map(item => {
                    result.push({
                        'region': item[(this.lang == 'tc') ? 'nameZh' : 'nameEng'],
                        'location': location[item.alias]
                    })
                })
            }
            return result
        }
    },
    watch: {
        options: {
            handler() {
                this.getAllPackage()
            }
        },
        selectedMerchant: {
            handler() {
                this.getAllPackage()
            }
        }
    },
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async getAllPackage() {
            if (this.$route.query && this.$route.query.id) {
                this.selectedMerchant = this.$route.query.id
                this.$route.query.id = ''
            }

            this.loading = true
            try {
                var { sortBy, sortDesc, page, itemsPerPage } = this.options
                let order = (sortDesc[0]) ? -1 : 1
                let params = {
                    page: page,
                    perPage: itemsPerPage,
                    sort: sortBy,
                    order: order,
                }

                if (this.selectedMerchant != '') Object.assign(params, { merchantId: this.selectedMerchant })

                let result = await api.get('/servicePackages', { params })

                if (result) {
                    this.packageList = result.data.docs
                    this.totalService = result.data.totalDocs

                    if (sortBy.length === 1 || sortDesc.length === 1) {
                        this.packageList = this.packageList.sort((a, b) => {
                            const sortA = a[sortBy[0]]
                            const sortB = b[sortBy[0]]

                            if (sortDesc[0]) {
                                if (sortA < sortB) return 1
                                if (sortA > sortB) return -1
                                return 0
                            } else {
                                if (sortA < sortB) return -1
                                if (sortA > sortB) return 1
                                return 0
                            }
                        })
                    }
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
                this.loading = false
            }
            this.loading = false
        },
        async getAllMerchant() {
            let apiTarget = (this.permissions == 'admin') ? '/merchants/all' : '/merchants/published'

            try {
                let result = await api.get(apiTarget)

                if (result) {
                    this.merchantsList = (this.permissions == 'admin') ? result.data : result.data.docs
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
            }
        },
        async getAllService() {
            try {
                let result = await api.get('/services/all')

                if (result) {
                    this.serviceList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
            }
        },
        async getAllTeacher() {
            try {
                let result = await api.get('/teacher')

                if (result) {
                    this.teacherList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
            }
        },
        async getAllSessions() {
            try {
                let result = await api.get('/servicePackageSessions/all')

                if (result) {
                    this.sessionsList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
                this.loading = false
            }
        },
        async getAllAreas() {
            try {
                let result = await api.get('/setting/areas')

                if (result) {
                    this.areasList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
            }
        },
        async getAllCategories() {
            let apiTarget = (this.permissions == 'admin') ? '/category' : '/category/published'

            try {
                let result = await api.get(apiTarget)

                if (result) {
                    this.categoryList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
            }
        },
        async changeStatus(item, index) {
            let action = await this.$refs.confirmDialog.open({
                title: this.$t('package_service.update_status'),
                content: this.$t('package_service.qa_update_status') + item.name + `${(item.published) ? this.$t('package_service.qa_update_status_ban') : this.$t('package_service.qa_update_status_active')}?`,
            })

            if (action) {
                let result = false

                item.published = (item.published) ? false : true

                try {
                    result = await api.patch('/servicePackages/' + item["_id"], item)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                if (result) {
                    this.packageList[index].status = item.status
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.success_update_status'),
                        icon: 'mdi-check',
                    })
                }
            }
            this.getAllPackage()
        },
        async copy(item) {
            let action = await this.$refs.confirmDialog.open({
                title: this.$t('package_service.copy_package'),
                content: this.$t('package_service.copy_content'),
            })

            console.log(item);

            if (action) {
                let result = false

                try {
                    result = await api.get('/servicePackages/clone/' + item["_id"])
                    console.log(result);
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                if (result) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.success_create'),
                        icon: 'mdi-check',
                    })
                }
            }
            this.getAllPackage();

        },
        create() {
            this.$refs.dialog.open('create', '', 'package')
        },
        edit(item) {
            this.$refs.dialog.open('edit', item, 'package')
        },
        showStatus(published) {
            return (published) ? this.$t('common.enabled') : this.$t('common.disabled');
        },
    },
    data: () => ({
        headers: [
            { i18n: 'service.name', value: 'name' },
            { i18n: 'common.merchant', value: 'merchantId' },
            { i18n: 'common.price', value: 'price' },
            { i18n: 'common.created_at', value: 'createdAt' },
            { i18n: 'common.status', value: 'published' },
            { i18n: 'common.edit', value: '', sortable: false },
            { i18n: 'common.copy', value: '', sortable: false },
        ],
        searchByName: '',
        packageList: [],
        merchantsList: [],
        teacherList: [],
        serviceList: [],
        sessionsList: [],
        areasList: [],
        categoryList: [],
        loading: false,
        selectedMerchant: null,
        totalService: 0,
        options: {},
    })
}
</script>