<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      width="800"
    >
      <v-toolbar dark >
          <v-btn icon @click="close()">
              <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('package_session.select_time') }}</v-toolbar-title>
          <v-spacer/>
          <v-btn dark text @click="save">OK</v-btn>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                      mobile-breakpoint=0
                      v-model="selectDataTime"
                      :headers="i18nDataTimeHeader"
                      :items="dataTimeList"
                      item-key="_id"
                      class="elevation-1"
                      show-select
                      dense
                  />
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import api from '@/api'

export default {
    computed:{
        ...mapState(['lang']),
        i18nDataTimeHeader(){
          let result = this.dataTimeHeader

          result.map( item => {
              item.text = this.$t(item.i18n)
          })

          return result
        }
    },
    methods:{
      ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
      async open(serviceID, index, serviceDateTimesItem){
        this.selectedIndex = index
        this.loading = true
          try{
              let result = await api.get('/serviceDateTimes/by-service/'+serviceID)

              if(result){
                let tempArr = Object.values(result.data.serviceDateTimes)

                this.dataTimeList = tempArr.map(item => {
                  return { _id:item._id , startAt: this.$moment(item.startAt).format('YYYY/MM/DD HH:mm'), endAt: this.$moment(item.endAt).format('YYYY/MM/DD HH:mm')}
                })

                // For edit
                if( serviceDateTimesItem ){
                  this.dataTimeList.map(item => {
                    serviceDateTimesItem.map(dateTimeItem => {
                      if(item._id == dateTimeItem._id){
                        this.selectDataTime.push(item)
                      }
                    })
                  })
                }
              }
          } catch (error) {
              this.PUSH_GLOBAL_SNACK_MESSAGES({
                  message: error.response.data.message,
                  icon   : 'mdi-close',
              })
              this.loading = false
          }
          this.dialog = true
          this.loading = false
      },
      save(){
        this.$emit('save', this.selectDataTime, this.selectedIndex)
        this.dialog = false
        this.reset()
      },
      close(){
        this.dialog = false
        this.reset()
      },
      reset(){
        this.dataTimeList = []
        this.selectDataTime = []
        this.selectedIndex = ''
      }
    },
    data: () => ({
        dialog: false,
        loading: false,
        selectedIndex: '',
        dataTimeList: [],
        selectDataTime: [],
        dataTimeHeader: [
            {i18n: 'service.start_at',value: 'startAt'},
            {i18n: 'service.end_at',value: 'endAt'}
        ]
    })
}
</script>