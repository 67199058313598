<template>
    <v-container>
        <v-dialog v-model="dialog" persistent max-width="750" hide-overlay >
            <v-toolbar dark >
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('package_session.'+action) }}</v-toolbar-title>
                <v-spacer />
                <v-btn dark @click="save(action)">{{$t('common.save')}}</v-btn>
            </v-toolbar>
            <v-card >
                <v-card-text>
                    <v-container>
                        <v-form
                            ref="form"
                            v-model="formValid"
                            lazy-validation
                        >
                        <v-row>
                            <v-col cols="12" v-if="sessionDetailObj.merchantId">
                                <v-card class="px-3 mb-7" v-for="(item, key) in sessionDetailObj.availableServices" :key="key">
                                    <v-card-title>
                                        {{$t('common.service') + (key + 1)}}
                                        <v-spacer />
                                        <v-btn dark @click="removeMultiRow(key)">
                                            <v-icon>mdi-trash-can-outline</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" >
                                                <v-select
                                                    :items="filteredServiceList"
                                                    v-model="item.service"
                                                    item-value="_id"
                                                    item-text="name"
                                                    prepend-icon="mdi-order-bool-ascending-variant"
                                                    :label="$t('service_date_times.select')"
                                                    :rules="[v => !!v || $t('common.required')]"
                                                    :loading="serviceLoading"
                                                />
                                            </v-col>
                                            <v-col cols="12" v-if="item.serviceDateTimes != ''">
                                                <v-data-table
                                                    mobile-breakpoint=0
                                                    :headers="i18nDataTimeHeader"
                                                    :items="item.serviceDateTimes"
                                                    class="elevation-1"
                                                    dense
                                                >
                                                    <template v-slot:item="{ item }">
                                                        <tr>
                                                            <td>{{ $moment(item.startAt, 'YYYY/MM/DD HH:mm', true).isValid()? item.startAt : $moment(item.startAt).format('YYYY/MM/DD HH:mm') }} </td>
                                                            <td>{{ $moment(item.endAt, 'YYYY/MM/DD HH:mm', true).isValid()? item.endAt : $moment(item.endAt).format('YYYY/MM/DD HH:mm') }} </td>
                                                        </tr>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                            <v-col class="justify-center d-flex flex-row" v-if="item.service != ''">
                                                <v-btn @click="openSelectDateTime(item.service, key, item.serviceDateTimes)">{{$t('package_session.select_time')}}</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col v-if="sessionDetailObj.merchantId" class="justify-center d-flex flex-row">
                                <v-btn dark @click="addNewMultiRow" >
                                    {{$t('package_session.add_session')}}
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row v-if="action == 'edit'">
                            <v-col cols="6">
                                <v-text-field
                                    :value="$moment(sessionDetailObj.updatedAt).format('YYYY/MM/DD HH:mm')"
                                    disabled
                                    :label="$t('common.updated_at')"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    :value="$moment(sessionDetailObj.createdAt).format('YYYY/MM/DD HH:mm')"
                                    disabled
                                    :label="$t('common.created_at')"
                                />
                            </v-col>
                        </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <DateTimeDialog
            ref="dialog"
            @save="saveDataTime"
        />
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import DateTimeDialog from "@/views/PackageSession/SessionDateTimeDialog"
import api from '@/api'

export default {
    components:{
        DateTimeDialog
    },
    mounted() {
        this.getAllMerchants()
        this.getAllService()
    },
    computed:{
        ...mapState(['lang', 'permissions', 'stateMerchantId', 'stateMerchantName']),
        i18nDataTimeHeader(){
            let result = this.dataTimeHeader

            result.map( item => {
                item.text = this.$t(item.i18n)
            })

            return result
        },
        filteredServiceList(){
            let result = []
            if(this.sessionDetailObj.merchantId){
                this.serviceList.map(item => {
                    if(item.merchantId == this.sessionDetailObj.merchantId){
                        result.push({name: item.name, _id: item._id})
                    }
                })
            }

            return result
        }
    },
    watch: {
    },
    methods:{
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async open(merchantId){
            let id = (this.permissions == 'admin')? merchantId._id  : merchantId
            this.sessionDetailObj.merchantId = id
            this.dialog = true
        },
        async save(){
            let validated = this.$refs.form.validate()


            for (const [key, session] of Object.entries(this.sessionDetailObj.availableServices)) { key
                if(session.serviceDateTimes == '' || !session.serviceDateTimes){
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_select_time'),
                        icon   : 'mdi-close',
                    })
                    validated = false
                    return false
                }
            }

            if(validated){
                let result = false
                this.loading = true

                try {
                    result = await api.post('/servicePackageSessions', this.sessionDetailObj)

                    if(result) {
                        this.$emit('refresh', result.data)
                        this.resetForm()
                        this.dialog = false
                    }
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon   : 'mdi-close',
                    })
                }
            }

            this.loading = false
        },
        async getAllServiceDateTimeByID(id) {
            let returnArr = []

            try{
                let result = await api.get('/serviceDateTimes/by-service/'+id)

                if(result){
                    returnArr = result.data.serviceDateTimes.map(item => {
                        return { _id:item._id , startAt: this.$moment(item.startAt).format('YYYY/MM/DD HH:mm'), endAt: this.$moment(item.endAt).format('YYYY/MM/DD HH:mm')}
                    })
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }

            return returnArr
        },
        async getAllMerchants() {
            let apiTarget = (this.permissions == 'admin')? '/merchants/all' : '/merchants/published'

            try{
                let result = await api.get(apiTarget)

                if(result){
                    this.merchantsList = (this.permissions == 'admin')? result.data : result.data.docs
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async getAllService() {
            try{
                let result = await api.get('/services/all')

                if(result){
                    this.serviceList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        addNewMultiRow() {
            this.sessionDetailObj.availableServices.push({ name: '', service: '', serviceDateTimes:[] })
        },
        removeMultiRow(index){
            this.sessionDetailObj.availableServices.splice(index, 1)
        },
        showStatus(status) {
			return status ? this.$t('common.enabled') : this.$t('common.disabled')
		},
        openSelectDateTime(serviceID, index, serviceDateTimesItem){
            this.$refs.dialog.open(serviceID, index, serviceDateTimesItem)
        },
        saveDataTime(selectedDateTime, index){
            this.sessionDetailObj.availableServices[index].serviceDateTimes = selectedDateTime
        },
        close(){
            this.resetForm()
            this.dialog = false
        },
        resetForm() {
            this.selectedServiceDateTimes = []
            this.dateTimeID = []
            this.selectedService = '',

            this.sessionDetailObj = {
                name: '',
                merchantId: '',
                availableServices:[
                    {
                        service:'',
                        serviceDateTimes: []
                    }
                ],
            }
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        sessionDetailObj:{
            name: '',
            merchantId: '',
            availableServices:[
                {
                    service:'',
                    serviceDateTimes: []
                }
            ],
        },
        dataTimeHeader: [
            {i18n: 'service.start_at',value: 'startAt'},
            {i18n: 'service.end_at',value: 'endAt'}
        ],
        dateTimeID: [],
        selectedServiceDateTimes: [],
        serviceList: [],
        merchantsList: [],
        selectedService:'',
        action: 'create',
        formValid: true,
        serviceLoading: false
    })
}
</script>